/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const dashboardQuery = /* GraphQL */ `
  query DashboardQuery {
    dashboardQuery
  }
`;
export const downloadUserList = /* GraphQL */ `
  query DownloadUserList {
    downloadUserList
  }
`;
export const listFeedback = /* GraphQL */ `
  query ListFeedback(
    $filter: ModelFeedbackFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFeedback(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        feedback
        page
        createdAt
        updatedAt
        user {
          id
          firstName
          lastName
          email
          phoneno
          title
          certifications
          coverageAreas
          execSponsor
          partnerId
          trainedById
          userStatus
          createdAt
          updatedAt
          partner {
            id
            spmsId
            name
            emailDomain
            headquarters
            coverageAreas
            specialtyDomains
            geo
            awsTeam {
              name
              email
              phoneno
              role
            }
            createdAt
            updatedAt
            users {
              nextToken
            }
          }
        }
      }
      nextToken
    }
  }
`;
export const listPartners = /* GraphQL */ `
  query ListPartners(
    $filter: ModelPartnerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPartners(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        spmsId
        name
        emailDomain
        headquarters
        coverageAreas
        specialtyDomains
        geo
        awsTeam {
          name
          email
          phoneno
          role
        }
        createdAt
        updatedAt
        users {
          items {
            id
            firstName
            lastName
            email
            phoneno
            title
            certifications
            coverageAreas
            execSponsor
            partnerId
            trainedById
            userStatus
            createdAt
            updatedAt
            partner {
              id
              spmsId
              name
              emailDomain
              headquarters
              coverageAreas
              specialtyDomains
              geo
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getPartner = /* GraphQL */ `
  query GetPartner($id: ID!) {
    getPartner(id: $id) {
      id
      spmsId
      name
      emailDomain
      headquarters
      coverageAreas
      specialtyDomains
      geo
      awsTeam {
        name
        email
        phoneno
        role
      }
      createdAt
      updatedAt
      users {
        items {
          id
          firstName
          lastName
          email
          phoneno
          title
          certifications
          coverageAreas
          execSponsor
          partnerId
          trainedById
          userStatus
          createdAt
          updatedAt
          partner {
            id
            spmsId
            name
            emailDomain
            headquarters
            coverageAreas
            specialtyDomains
            geo
            awsTeam {
              name
              email
              phoneno
              role
            }
            createdAt
            updatedAt
            users {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        email
        phoneno
        title
        certifications
        coverageAreas
        execSponsor
        partnerId
        trainedById
        userStatus
        createdAt
        updatedAt
        partner {
          id
          spmsId
          name
          emailDomain
          headquarters
          coverageAreas
          specialtyDomains
          geo
          awsTeam {
            name
            email
            phoneno
            role
          }
          createdAt
          updatedAt
          users {
            items {
              id
              firstName
              lastName
              email
              phoneno
              title
              certifications
              coverageAreas
              execSponsor
              partnerId
              trainedById
              userStatus
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      firstName
      lastName
      email
      phoneno
      title
      certifications
      coverageAreas
      execSponsor
      partnerId
      trainedById
      userStatus
      createdAt
      updatedAt
      partner {
        id
        spmsId
        name
        emailDomain
        headquarters
        coverageAreas
        specialtyDomains
        geo
        awsTeam {
          name
          email
          phoneno
          role
        }
        createdAt
        updatedAt
        users {
          items {
            id
            firstName
            lastName
            email
            phoneno
            title
            certifications
            coverageAreas
            execSponsor
            partnerId
            trainedById
            userStatus
            createdAt
            updatedAt
            partner {
              id
              spmsId
              name
              emailDomain
              headquarters
              coverageAreas
              specialtyDomains
              geo
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      partnerId
      userId
      customers
      geo
      location
      deliveryMethod
      topic
      date
      registrationCount
      attendeeCount
      status
      multiCustomer
      comment
      awspsa
      oppty
      opportunities
      csat {
        surveyId
        surveyType
        surveyUrl
        surveyResultsKey
        score
        feedback {
          question
          score
          comments
        }
        qualtricsApiKeyParam
      }
      test
      createdAt
      updatedAt
      partner {
        id
        spmsId
        name
        emailDomain
        headquarters
        coverageAreas
        specialtyDomains
        geo
        awsTeam {
          name
          email
          phoneno
          role
        }
        createdAt
        updatedAt
        users {
          items {
            id
            firstName
            lastName
            email
            phoneno
            title
            certifications
            coverageAreas
            execSponsor
            partnerId
            trainedById
            userStatus
            createdAt
            updatedAt
            partner {
              id
              spmsId
              name
              emailDomain
              headquarters
              coverageAreas
              specialtyDomains
              geo
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      user {
        id
        firstName
        lastName
        email
        phoneno
        title
        certifications
        coverageAreas
        execSponsor
        partnerId
        trainedById
        userStatus
        createdAt
        updatedAt
        partner {
          id
          spmsId
          name
          emailDomain
          headquarters
          coverageAreas
          specialtyDomains
          geo
          awsTeam {
            name
            email
            phoneno
            role
          }
          createdAt
          updatedAt
          users {
            items {
              id
              firstName
              lastName
              email
              phoneno
              title
              certifications
              coverageAreas
              execSponsor
              partnerId
              trainedById
              userStatus
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        partnerId
        userId
        customers
        geo
        location
        deliveryMethod
        topic
        date
        registrationCount
        attendeeCount
        status
        multiCustomer
        comment
        awspsa
        oppty
        opportunities
        csat {
          surveyId
          surveyType
          surveyUrl
          surveyResultsKey
          score
          feedback {
            question
            score
            comments
          }
          qualtricsApiKeyParam
        }
        test
        createdAt
        updatedAt
        partner {
          id
          spmsId
          name
          emailDomain
          headquarters
          coverageAreas
          specialtyDomains
          geo
          awsTeam {
            name
            email
            phoneno
            role
          }
          createdAt
          updatedAt
          users {
            items {
              id
              firstName
              lastName
              email
              phoneno
              title
              certifications
              coverageAreas
              execSponsor
              partnerId
              trainedById
              userStatus
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        user {
          id
          firstName
          lastName
          email
          phoneno
          title
          certifications
          coverageAreas
          execSponsor
          partnerId
          trainedById
          userStatus
          createdAt
          updatedAt
          partner {
            id
            spmsId
            name
            emailDomain
            headquarters
            coverageAreas
            specialtyDomains
            geo
            awsTeam {
              name
              email
              phoneno
              role
            }
            createdAt
            updatedAt
            users {
              nextToken
            }
          }
        }
      }
      nextToken
    }
  }
`;
export const eventsByPartnerByDate = /* GraphQL */ `
  query EventsByPartnerByDate(
    $partnerId: ID
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventsByPartnerByDate(
      partnerId: $partnerId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partnerId
        userId
        customers
        geo
        location
        deliveryMethod
        topic
        date
        registrationCount
        attendeeCount
        status
        multiCustomer
        comment
        awspsa
        oppty
        opportunities
        csat {
          surveyId
          surveyType
          surveyUrl
          surveyResultsKey
          score
          feedback {
            question
            score
            comments
          }
          qualtricsApiKeyParam
        }
        test
        createdAt
        updatedAt
        partner {
          id
          spmsId
          name
          emailDomain
          headquarters
          coverageAreas
          specialtyDomains
          geo
          awsTeam {
            name
            email
            phoneno
            role
          }
          createdAt
          updatedAt
          users {
            items {
              id
              firstName
              lastName
              email
              phoneno
              title
              certifications
              coverageAreas
              execSponsor
              partnerId
              trainedById
              userStatus
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        user {
          id
          firstName
          lastName
          email
          phoneno
          title
          certifications
          coverageAreas
          execSponsor
          partnerId
          trainedById
          userStatus
          createdAt
          updatedAt
          partner {
            id
            spmsId
            name
            emailDomain
            headquarters
            coverageAreas
            specialtyDomains
            geo
            awsTeam {
              name
              email
              phoneno
              role
            }
            createdAt
            updatedAt
            users {
              nextToken
            }
          }
        }
      }
      nextToken
    }
  }
`;
export const eventsByUserByDate = /* GraphQL */ `
  query EventsByUserByDate(
    $userId: ID
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventsByUserByDate(
      userId: $userId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partnerId
        userId
        customers
        geo
        location
        deliveryMethod
        topic
        date
        registrationCount
        attendeeCount
        status
        multiCustomer
        comment
        awspsa
        oppty
        opportunities
        csat {
          surveyId
          surveyType
          surveyUrl
          surveyResultsKey
          score
          feedback {
            question
            score
            comments
          }
          qualtricsApiKeyParam
        }
        test
        createdAt
        updatedAt
        partner {
          id
          spmsId
          name
          emailDomain
          headquarters
          coverageAreas
          specialtyDomains
          geo
          awsTeam {
            name
            email
            phoneno
            role
          }
          createdAt
          updatedAt
          users {
            items {
              id
              firstName
              lastName
              email
              phoneno
              title
              certifications
              coverageAreas
              execSponsor
              partnerId
              trainedById
              userStatus
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        user {
          id
          firstName
          lastName
          email
          phoneno
          title
          certifications
          coverageAreas
          execSponsor
          partnerId
          trainedById
          userStatus
          createdAt
          updatedAt
          partner {
            id
            spmsId
            name
            emailDomain
            headquarters
            coverageAreas
            specialtyDomains
            geo
            awsTeam {
              name
              email
              phoneno
              role
            }
            createdAt
            updatedAt
            users {
              nextToken
            }
          }
        }
      }
      nextToken
    }
  }
`;
