/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCsatSurvey = /* GraphQL */ `
  mutation CreateCsatSurvey($input: CreateCsatSurveyInput) {
    createCsatSurvey(input: $input)
  }
`;
export const getCsatSurveyResults = /* GraphQL */ `
  mutation GetCsatSurveyResults($input: GetCsatSurveyResultsInput) {
    getCsatSurveyResults(input: $input)
  }
`;
export const cancelEvent = /* GraphQL */ `
  mutation CancelEvent($input: CancelEventInput) {
    cancelEvent(input: $input)
  }
`;
export const createCognitoUser = /* GraphQL */ `
  mutation CreateCognitoUser($input: CreateCognitoUserInput) {
    createCognitoUser(input: $input)
  }
`;
export const disableUserAccount = /* GraphQL */ `
  mutation DisableUserAccount($input: DisableUserAccountInput) {
    disableUserAccount(input: $input)
  }
`;
export const addUserToGroup = /* GraphQL */ `
  mutation AddUserToGroup($input: AddUserToGroupInput) {
    addUserToGroup(input: $input)
  }
`;
export const createPartner = /* GraphQL */ `
  mutation CreatePartner($input: CreatePartnerInput!) {
    createPartner(input: $input) {
      id
      spmsId
      name
      emailDomain
      headquarters
      coverageAreas
      specialtyDomains
      geo
      awsTeam {
        name
        email
        phoneno
        role
      }
      createdAt
      updatedAt
      users {
        items {
          id
          firstName
          lastName
          email
          phoneno
          title
          certifications
          coverageAreas
          execSponsor
          partnerId
          trainedById
          userStatus
          createdAt
          updatedAt
          partner {
            id
            spmsId
            name
            emailDomain
            headquarters
            coverageAreas
            specialtyDomains
            geo
            awsTeam {
              name
              email
              phoneno
              role
            }
            createdAt
            updatedAt
            users {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const updatePartner = /* GraphQL */ `
  mutation UpdatePartner($input: UpdatePartnerInput!) {
    updatePartner(input: $input) {
      id
      spmsId
      name
      emailDomain
      headquarters
      coverageAreas
      specialtyDomains
      geo
      awsTeam {
        name
        email
        phoneno
        role
      }
      createdAt
      updatedAt
      users {
        items {
          id
          firstName
          lastName
          email
          phoneno
          title
          certifications
          coverageAreas
          execSponsor
          partnerId
          trainedById
          userStatus
          createdAt
          updatedAt
          partner {
            id
            spmsId
            name
            emailDomain
            headquarters
            coverageAreas
            specialtyDomains
            geo
            awsTeam {
              name
              email
              phoneno
              role
            }
            createdAt
            updatedAt
            users {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      firstName
      lastName
      email
      phoneno
      title
      certifications
      coverageAreas
      execSponsor
      partnerId
      trainedById
      userStatus
      createdAt
      updatedAt
      partner {
        id
        spmsId
        name
        emailDomain
        headquarters
        coverageAreas
        specialtyDomains
        geo
        awsTeam {
          name
          email
          phoneno
          role
        }
        createdAt
        updatedAt
        users {
          items {
            id
            firstName
            lastName
            email
            phoneno
            title
            certifications
            coverageAreas
            execSponsor
            partnerId
            trainedById
            userStatus
            createdAt
            updatedAt
            partner {
              id
              spmsId
              name
              emailDomain
              headquarters
              coverageAreas
              specialtyDomains
              geo
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent($input: CreateEventInput!) {
    createEvent(input: $input) {
      id
      partnerId
      userId
      customers
      geo
      location
      deliveryMethod
      topic
      date
      registrationCount
      attendeeCount
      status
      multiCustomer
      comment
      awspsa
      oppty
      opportunities
      csat {
        surveyId
        surveyType
        surveyUrl
        surveyResultsKey
        score
        feedback {
          question
          score
          comments
        }
        qualtricsApiKeyParam
      }
      test
      createdAt
      updatedAt
      partner {
        id
        spmsId
        name
        emailDomain
        headquarters
        coverageAreas
        specialtyDomains
        geo
        awsTeam {
          name
          email
          phoneno
          role
        }
        createdAt
        updatedAt
        users {
          items {
            id
            firstName
            lastName
            email
            phoneno
            title
            certifications
            coverageAreas
            execSponsor
            partnerId
            trainedById
            userStatus
            createdAt
            updatedAt
            partner {
              id
              spmsId
              name
              emailDomain
              headquarters
              coverageAreas
              specialtyDomains
              geo
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      user {
        id
        firstName
        lastName
        email
        phoneno
        title
        certifications
        coverageAreas
        execSponsor
        partnerId
        trainedById
        userStatus
        createdAt
        updatedAt
        partner {
          id
          spmsId
          name
          emailDomain
          headquarters
          coverageAreas
          specialtyDomains
          geo
          awsTeam {
            name
            email
            phoneno
            role
          }
          createdAt
          updatedAt
          users {
            items {
              id
              firstName
              lastName
              email
              phoneno
              title
              certifications
              coverageAreas
              execSponsor
              partnerId
              trainedById
              userStatus
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent($input: UpdateEventInput!) {
    updateEvent(input: $input) {
      id
      partnerId
      userId
      customers
      geo
      location
      deliveryMethod
      topic
      date
      registrationCount
      attendeeCount
      status
      multiCustomer
      comment
      awspsa
      oppty
      opportunities
      csat {
        surveyId
        surveyType
        surveyUrl
        surveyResultsKey
        score
        feedback {
          question
          score
          comments
        }
        qualtricsApiKeyParam
      }
      test
      createdAt
      updatedAt
      partner {
        id
        spmsId
        name
        emailDomain
        headquarters
        coverageAreas
        specialtyDomains
        geo
        awsTeam {
          name
          email
          phoneno
          role
        }
        createdAt
        updatedAt
        users {
          items {
            id
            firstName
            lastName
            email
            phoneno
            title
            certifications
            coverageAreas
            execSponsor
            partnerId
            trainedById
            userStatus
            createdAt
            updatedAt
            partner {
              id
              spmsId
              name
              emailDomain
              headquarters
              coverageAreas
              specialtyDomains
              geo
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      user {
        id
        firstName
        lastName
        email
        phoneno
        title
        certifications
        coverageAreas
        execSponsor
        partnerId
        trainedById
        userStatus
        createdAt
        updatedAt
        partner {
          id
          spmsId
          name
          emailDomain
          headquarters
          coverageAreas
          specialtyDomains
          geo
          awsTeam {
            name
            email
            phoneno
            role
          }
          createdAt
          updatedAt
          users {
            items {
              id
              firstName
              lastName
              email
              phoneno
              title
              certifications
              coverageAreas
              execSponsor
              partnerId
              trainedById
              userStatus
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
    }
  }
`;
export const createFeedback = /* GraphQL */ `
  mutation CreateFeedback($input: CreateFeedbackInput!) {
    createFeedback(input: $input) {
      id
      userId
      feedback
      page
      createdAt
      updatedAt
      user {
        id
        firstName
        lastName
        email
        phoneno
        title
        certifications
        coverageAreas
        execSponsor
        partnerId
        trainedById
        userStatus
        createdAt
        updatedAt
        partner {
          id
          spmsId
          name
          emailDomain
          headquarters
          coverageAreas
          specialtyDomains
          geo
          awsTeam {
            name
            email
            phoneno
            role
          }
          createdAt
          updatedAt
          users {
            items {
              id
              firstName
              lastName
              email
              phoneno
              title
              certifications
              coverageAreas
              execSponsor
              partnerId
              trainedById
              userStatus
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
    }
  }
`;
export const deleteFeedback = /* GraphQL */ `
  mutation DeleteFeedback($input: DeleteFeedbackInput!) {
    deleteFeedback(input: $input) {
      id
      userId
      feedback
      page
      createdAt
      updatedAt
      user {
        id
        firstName
        lastName
        email
        phoneno
        title
        certifications
        coverageAreas
        execSponsor
        partnerId
        trainedById
        userStatus
        createdAt
        updatedAt
        partner {
          id
          spmsId
          name
          emailDomain
          headquarters
          coverageAreas
          specialtyDomains
          geo
          awsTeam {
            name
            email
            phoneno
            role
          }
          createdAt
          updatedAt
          users {
            items {
              id
              firstName
              lastName
              email
              phoneno
              title
              certifications
              coverageAreas
              execSponsor
              partnerId
              trainedById
              userStatus
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
    }
  }
`;
export const resendCognitoConfirmation = /* GraphQL */ `
  mutation ResendCognitoConfirmation($input: ResendCognitoConfirmationInput) {
    resendCognitoConfirmation(input: $input)
  }
`;
