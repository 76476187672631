/* eslint-disable react/display-name */
import React from "react";

import { Button } from "@amzn/awsui-components-react";

import {
  formatDate,
  immersionDayTopicOptions,
  geoOptions
} from "../../../utils";

export const COLUMN_DEFINITIONS = history => [
  {
    id: "id",
    header: () => "ID",
    cell: item => item.id,
    minWidth: "100px",
    allowLineWrap: true
  },
  {
    id: "partnerId",
    header: () => "Partner ID",
    cell: item => item.partner.id,
    minWidth: "180px",
    allowLineWrap: true
  },
  {
    id: "date",
    header: () => "Date",
    cell: item => (
      <Button variant="link" onClick={() => history.push(`/event/${item.id}`)}>
        {formatDate(item.date)}
      </Button>
    ),
    minWidth: "150px",
    allowLineWrap: true
  },
  {
    id: "partnerName",
    header: () => "Partner Name",
    cell: item => item.partnerName,
    minWidth: "180px",
    allowLineWrap: true
  },
  {
    id: "owner",
    header: () => "Event Owner",
    cell: item => item.eventOwner,
    minWidth: "120px",
    allowLineWrap: true
  },
  {
    id: "ownerEmail",
    cell: item => item.eventOwnerEmail,
    header: () => "Event Owner Email",
    minWidth: "120px",
    allowLineWrap: true
  },
  {
    id: "geo",
    header: () => "Geo",
    cell: item => item.geo,
    minWidth: "55px",
    allowLineWrap: true
  },
  {
    id: "location",
    header: () => "Location",
    cell: item => item.location,
    minWidth: "100px",
    allowLineWrap: true
  },
  {
    id: "topic",
    header: () => "Topic",
    cell: item => item.topic,
    minWidth: "85px",
    allowLineWrap: true
  },
  {
    id: "deliveryMethod",
    header: () => "Delivery Method",
    cell: item => item.deliveryMethod,
    minWidth: "75px",
    allowLineWrap: true
  },
  {
    id: "csat",
    header: () => "CSAT Score",
    cell: item => (item.csat.score && item.csat.score > -1 ? item.csat.score : "-"),
    minWidth: "50px"
  }
];

export const SORTABLE_COLUMNS = [
  { id: "partnerName", field: "partnerName" },
  { id: "owner", field: "eventOwner" },
  { id: "date", field: "date" },
  { id: "geo", field: "geo" },
  { id: "topic", field: "topic" },
  { id: "deliveryMethod", field: "deliveryMethod" },
  {
    id: "csat",
    comparator: (item1, item2) => {
      if (item1.csat.score === item2.csat.score) return 0;
      else return item1.csat.score > item2.csat.score ? 1 : -1;
    }
  }
];

export const CONTENT_SELECTOR_OPTIONS = [
  {
    label: "Table settings",
    options: [
      { id: "id", label: "ID", editable: false, visible: false },
      { id: "partnerId", label: "Partner ID", editable: false, visible: false },
      { id: "date", label: "Date", editable: true, visible: true },
      {
        id: "partnerName",
        label: "Partner Name",
        editable: true,
        visible: true
      },
      { id: "owner", label: "Event Owner", editable: true, visible: true },
      {
        id: "ownerEmail",
        label: "Owner Email",
        editable: true,
        visible: false
      },
      { id: "geo", label: "Geo", editable: true, visible: false },
      { id: "location", label: "Location", editable: true, visible: false },
      { id: "topic", label: "Topic", editable: true, visible: true },
      {
        id: "deliveryMethod",
        label: "Delivery Method",
        editable: true,
        visible: false
      },
      { id: "csat", label: "CSAT Score", editable: true, visible: true }
    ]
  }
];

export const PAGE_SELECTOR_OPTIONS = [
  { value: 10, label: "10" },
  { value: 30, label: "30" },
  { value: 50, label: "50" }
];

export const FILTERING_OPTIONS = [
  {
    groupValuesLabel: "Partner Values",
    propertyKey: "partnerName",
    propertyLabel: "Partner Name",
    values: []
  },
  {
    groupValuesLabel: "Geo Values",
    propertyKey: "geo",
    propertyLabel: "Geo",
    values: geoOptions.map(o => o.label)
  },
  {
    groupValuesLabel: "Topic Values",
    propertyKey: "topic",
    propertyLabel: "Topic",
    values: immersionDayTopicOptions.map(o => o.label)
  }
];
